<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col>
          <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" @row-dblclick="dbClickJp" class="mtrTab">
            <el-table-column label="材料采购号" prop="modr_no"/>
            <el-table-column label="成品采购号" prop="podr_no"/>
            <el-table-column label="订单总箱数" prop="fcre_tbox"/>
            <el-table-column label="客户简称" prop="cust_abbr" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.cust_abbr">{{scope.row.cust_abbr}}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="采购经办人" prop="puca_stff_name" align="center"/>
            <el-table-column label="收货状态" show-overflow-tooltip align="center">
              <template v-slot="scope">
                <el-tag v-if="scope.row.fcre_status === 1" type="warning" size="mini">部分收货</el-tag>
                <el-tag v-if="scope.row.fcre_status === 0" type="info" size="mini">未收货</el-tag>
                <el-tag v-if="scope.row.fcre_status === 2"  type="success" size="mini">已收货</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import { fcreAPI } from "@api/modules/fcre";
import helper from "@assets/js/helper.js"
export default {
  name: "ShwePush9001",
  data(){
    return{
      tableData: [],
      loading: true,
    }
  },
  created() {
    this.initData()
  },
  mounted() {
  },
  filters: {
		// 时间转换
		formatDate(row) {
			return helper.toStringDay(row)
		},
	},
  methods: {
    initData() {
      this.loading = true;
      this.getPushList()
    },
    //获取信息
    getPushList(){
      get(fcreAPI.getFcreByScan)
          .then(res=>{
            if(res.data.code === 0){
              this.tableData = res.data.data.list
              setTimeout(() => {
                this.loading = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 双击
    dbClickJp(row){
      post(fcreAPI.editFcreSacn,{fcre_id: row.fcre_id,scan: 0,})
      .then(res => {
        if (res.data.code === 0) {
          this.initData()
          this.jump('/fcre_list',{perm_id:145,modr_no:row.modr_no})
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      }).catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    buttonRefresh(){
      this.initData()
    }
  }
}
</script>

<style scoped lang="scss">
.vd_completed{
  color: $color-secondary-text;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.mtrTab {
  margin-top:10px ;
  cursor: pointer;
}
</style>